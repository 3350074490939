import React, { Component } from "react";
import { StaticImage } from "gatsby-plugin-image";

export default class Contact extends Component {
  render() {
    const { data: gmail } = this.props;
    return (
      <div className="contact section" id="Contact">
        <div className="container">
          <div className="section-head">
            <h2 className="text-center">Contact</h2>
          </div>
          <p className="u_txtCenter">
            お問い合わせはLINEで御対応いたします。<br />
            下記を参考の上「友だちを追加」後、<br />
            お問い合わせください。<br /><br />
            LINEが使用できない方は、以下メールまでお問合せください<br />
            <a href={`mailto:${gmail}`}>{gmail}</a>
          </p>
          <div className="u_flex u_spHide">
            <div className="u_wHalf u_pad">
              <div className="u_borderGray u_padSmall">
                <h3 className="u_txtCenter">QRコードで登録する</h3>
                <hr />
                <ol>
                  <li>お手元のスマホでLINEアプリを起動</li>
                  <li>
                    ホーム画面右上のアイコン
                    <StaticImage
                      alt="friend"
                      style={{display: "inline-block"}} src="../images/line_plus_icon.png"
                      width={20}
                      height={20} />
                    をクリック
                  </li>
                  <li>「QRコード」をタップして下記のQRコードを撮影</li>
                  <li>｢追加｣をタップして、トーク画面よりお問い合わせください</li>
                </ol>
                <p className="u_txtCenter">LINE上のカメラで下記のQRコードを読み取ってください。</p>
                <div className="u_txtCenter u_pad">
                  <img className="u_borderGray" src="https://qr-official.line.me/sid/M/634iudsr.png" />
                </div>
              </div>    
            </div>
            <div className="u_wHalf u_pad">
              <div className="u_borderGray u_padSmall">
                <h3 className="u_txtCenter">ID検索で登録する</h3>
                <hr />
                <ol>
                  <li>お手元のスマホでLINEアプリを起動</li>
                  <li>
                    ホーム画面右上のアイコン
                    <StaticImage
                      alt="friend"
                      style={{display: "inline-block"}} src="../images/line_plus_icon.png"
                      width={20}
                      height={20} />
                    をクリック
                  </li>
                  <li>
                    「検索」をタップして以下のIDを入力して検索
                    <h4 className="u_borderGray u_marSmall u_padSmall u_txtCenter">@634iudsr</h4>
                  </li>
                  <li>｢追加｣をタップして、トーク画面よりお問い合わせください</li>
                </ol>
              </div>
            </div>
          </div>
          <div className="u_pcHide  u_pad">
            <div className="u_borderGray u_padSmall">
              <h3 className="u_txtCenter">友達追加</h3>
              <hr />
              <ol>
                <li>下記ボタンをクリックし、遷移先で｢友だちを追加｣</li>
                <li>トーク画面よりお問い合わせください</li>
              </ol>
              <div className="u_txtCenter">
                <a href="https://lin.ee/tZSRPVg">
                  <img src="https://scdn.line-apps.com/n/line_add_friends/btn/ja.png" alt="友だち追加" height="36" border="0" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
