import React from "react";

const Company = () => (
  <div className="company section" id="Company">
    <div className="container">
      <div className="section-head">
        <h2>Company</h2>
      </div>
      <div>
        <table className="p-company__infotable c-font--hiragino_kakugo">
          <tbody>
            <tr>
              <th>名称</th>
              <td>株式会社あららぼ</td>
            </tr>
            <tr>
              <th>代表取締役</th>
              <td>西口広海</td>
            </tr>
            <tr>
              <th>設立日</th>
              <td>2021年12月22日</td>
            </tr>
            <tr>
              <th>住所</th>
              <td><address>〒150-0043 <br />東京都渋谷区道玄坂１丁目１０ー８ <br />渋谷道玄坂東急ビル２ＦーＣ</address></td>
            </tr>
            <tr>
              <th>資本金</th>
              <td>1,000,000円</td>
            </tr>
            <tr>
              <th>事業内容</th>
              <td>WEBシステム受託開発業</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
);

export default Company;
