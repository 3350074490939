import React from "react";
import Img from "gatsby-image";

const Greeting = ({ data }) => (
  <div className="greeting section" id="Greeting">
    <div className="container">
      <div className="section-head">
        <h2>Greeting</h2>
      </div>
      <div className="greeting-main row">
        <div className="left col-md-5 col-lg-4 mb-3">
          <Img
            fluid={data.photo.fluid}
            objectFit="cover"
            objectPosition="top center"
          />
        </div>
        <div className="left col-md-7 col-lg-8">
          <div className="greeting-details">
            <h2 className="sub-position">
              システム開発のオールラウンドプレーヤー
            </h2>
            <div
              dangerouslySetInnerHTML={{
                __html: data.description.childMarkdownRemark.html
              }}
            />
            <hr/>
            <h3 className="line-heading">代表取締役　西口広海（にしぐちひろみ）</h3>
            <div className="u_mbWide u_mtNarrow"
              dangerouslySetInnerHTML={{
                __html: data.profile.childMarkdownRemark.html
              }}
            />
            <div className="socials">
              <ul>
                <li>
                  <a
                    className="fab fa-facebook-f"
                    href={data.facebook}
                    target="_blank"
                    rel="noopener noreferrer"
                  ></a>
                </li>
                {/* <li>
                  <a
                    className="fab fa-twitter"
                    href={data.twitter}
                    target="_blank"
                    rel="noopener noreferrer"
                  ></a>
                </li> */}
                {/* <li>
                  <a
                    className="fab fa-instagram"
                    href={data.instagram}
                    target="_blank"
                    rel="noopener noreferrer"
                  ></a>
                </li> */}
                <li>
                  <a
                    className="fab fa-github"
                    href={data.github}
                    target="_blank"
                    rel="noopener noreferrer"
                  ></a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default Greeting;
