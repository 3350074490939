import React from "react";
import moment from "moment";

const News = ({ data }) =>  (
  <div className="news section" id="News">
    <div className="container">
      <div className="section-head">
        <h2>News</h2>
      </div>
      <ul className="news-list">
        {data.edges.map((item, index) => (
          <li key={index}>
            <div className="news-line">
              <p>{moment(item.node.createdAt).format("YYYY/MM/DD")}</p>
              {item.node.url
                ? <a href={item.node.url.url}>{item.node.title}</a>
                : item.node.title
              }
            </div>
            <hr className="u_mbNarrow"/>
          </li>
        ))}
      </ul>
    </div>
  </div>
)

export default News;