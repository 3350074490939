import React from "react";

const Partner = () => (
  <div className="partner section" id="Partner">
    <div className="container">
      <div className="section-head">
        <h2>Partner</h2>
      </div>
      <div className="col-12">
        <h4>COMMING SOON...</h4>
      </div>
    </div>
  </div>
);

export default Partner;
