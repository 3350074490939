import React, { Component } from "react";

export default class service extends Component {
  render() {
    const { data } = this.props;
    return (
      <div className="service section" id="Service">
        <div className="container">
          <div className="section-head">
            <h2>Service</h2>
          </div>
          <div className="row">
            <div className="col-lg-4 mb-3">
              <div className="service-main">
                <h3>システム開発</h3>
                <div className="u_mtNormal">
                  <h4>小規模システムを、柔軟に開発、素早く納品を実現します。</h4>
                  「実装→お客様ご確認→仕様調整」のサイクル(2~4週間)を繰り返しながら、
                  <br/>
                  仕様認識にズレのない形で開発を進めていきます
                </div>
              </div>
            </div>
            <div className="col-lg-4 mb-3">
              <div className="service-main">
                <h3>自動化ツールの開発</h3>
                <div className="u_mtNormal">
                  <h4>手作業を自動化するためのツールの開発を行います。</h4>
                  <hr />
                  開発例: 
                  <ul>
                    <li>
                      PDFの自動取得ツール
                      <br />
                      URLを指定してPDFを取得し、Google Driveに保存するという一連の流れを自動化
                    </li>
                  </ul>
                  <br />
                  
                </div>
              </div>
            </div>
            <div className="col-lg-4 mb-3">
              <div className="service-main">
                <h3>実装</h3>
                <div className="u_mtNormal">
                  <h4>フロントエンドコーディング</h4>
                  <ul>
                    <li>HTML / CSS / Javascript</li>
                    <li>React / Vue / Angular / Svelte / jQuery</li>
                  </ul>
                  など
                  <hr />
                  <h4>サーバーサイド実装</h4>
                  <ul>
                    <li>Ruby(Ruby on Rails)</li>
                    <li>Java(Spring Boot)</li>
                    <li>Python</li>
                    <li>Node(Express)</li>
                  </ul>
                  など
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
